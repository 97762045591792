import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  title:{
    color: "#005CB2",
    marginBottom:"20px",
    padding: "1.5rem 0rem 0rem 1rem",
    fontSize:"24px",
  },

}));

const Title  = ({data}) => { 
  const classes = useStyles();
	return (
       <Typography variant="h5" className={classes.title}>
          {data}
        </Typography>
)
}
export default Title;