import React from 'react'
import HelpContent from '../sections/HelpContent'
import HelpFeedBack from '../sections/HelpFeedBack'
import HelpForm from '../sections/HelpForm'
import HelpLearnNow from '../sections/HelpLearnNow'
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  root: {
    display: "flex",
    width: "100%",
    maxHeight: "auto",
    alignItems: "stretch",
    fontWeight: '300',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
      fontWeight: '300',
    }

  },
  box: {
    width: "33.33333%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }

  }

}));
const Support = ({ data }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <div className={classes.box}>
        <HelpContent data={data.help} />
      </div>
      <div className={classes.box}>
        <HelpForm data={data.contact} />
      </div>
      <div className={classes.box}>
        <HelpFeedBack data={data.feed_back} />
        <HelpLearnNow data={data.learn_now} />
      </div>

    </Container>

  )
}
export default Support;
