import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Title from "./Title"
import Alink from "../../common/ALink"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  title: {
    color: "#005CB2",
    marginBottom: "20px",
    padding: "1.5rem 0rem 0rem 1rem",
    fontSize: "24px",
  },
  question: {
    padding: "0 1rem 1rem 2rem",
    fontWeight: "300",
  },
  link: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      color: "#005CB2",
    },
  },
}))

const HelpContent = ({ data }) => {
  const classes = useStyles()
  return (
    <div>
      <Title className={classes.title} data={data.help_title} />
      {data.help_content.map(item => {
        return (
          <Typography className={classes.question}>
            <Alink className={classes.link} to={`/support/${item.id}`}>
              {item.question}
            </Alink>
          </Typography>
        )
      })}
    </div>
  )
}
export default HelpContent
