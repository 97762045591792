import React,{useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';


const useStyles = makeStyles((theme) =>({
  root:{
    maxWidth: "100%",  
    background: "#fffffff7",
    borderRadius: "25px",
    paddingBottom: "20px",
    
  },
  title:{
    color: "#005CB2",
    padding: "1.5rem 0rem 0rem 1rem",
    marginBottom: "20px",
    fontSize : "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  text:{
    width: '100%',
    background: '#e9ecec99',
    fontSize: "18px",
    "& input":{
      fontWeight: 300
    },
    "& textarea":{
      fontWeight: 300
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    }
  },
  label:{
    fontSize: "18px",// margin: '0.5rem 0rem 0rem 0rem'
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    }
  },

  row:{
    padding: '0 1rem 1rem 1rem'
  },
  button:{
    display: "block",
    padding:"0.5rem 1rem",
    margin: "0 auto",
  },
  errors:{
    color: '#FF0000',
    margin: '0.5rem 0 0 0.5rem',
    fontSize: "14px",
  }
}));


const HelpForm  = ({data}) => { 
  const classes = useStyles();
  
  
  const [state, setState] = useState({
    displayName: "",
    email: "",
    phone: "",
});

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = yup.object({
    displayName: yup
        .string()
        .required("Hãy nhập họ tên"),
    email: yup
        .string()
        .email("Format email không đúng")
        .required("Hãy nhập email"),
    phone: yup
        .string()
        .matches(phoneRegExp, 'Hãy nhập số điện thoại của bạn ')
        .min(10)
        .max(12)
        .required("Hãy nhập số điện thoại"),
  });

  const changeName = (e) =>{ 
  
    const new_e = {
        target: {
          name: e.target.id,
          value: e.target.value
        }
      };
    return formik.handleChange(new_e)
  }

  const formik = useFormik({
    initialValues: {
      ...state
    },
    validationSchema: validationSchema,
      onSubmit: (values) => {
        document.forms.entry.submit();
        let {displayName, email, phone} = values
        setState(prevState => ({
            ...prevState,
            displayName: displayName,
            email: email,
            phone: phone,
        }));
      },
    });

  return (
    <Container className={classes.container} >
      <Typography  variant="h6" gutterBottom className={classes.title}>Liên Hệ</Typography>
      <form className={classes.root}   onSubmit={formik.handleSubmit} name="entry" action="https://docs.google.com/forms/d/e/1FAIpQLSfwADD9hUDeRZD7Ct79DG9JhxF_FRO4TmBtP_Dt9O1apHdD4g/formResponse">
        <div className={classes.row}>
          <Typography  variant="h6" gutterBottom className={classes.label}>Họ và tên</Typography>
          <TextField 
            className={classes.text}
            variant="outlined"
            size="small"
            id="displayName"  
            name="entry.2085610544"
            placeholder="Nguyễn Văn A"
            value={formik.values.displayName}
            onChange={changeName}
            />
            {formik.errors.displayName && formik.touched.displayName && 
            (<div className={classes.errors}>{formik.errors.displayName}</div>)}
        </div>
        <div className={classes.row}>  
          <Typography  variant="h6" gutterBottom className={classes.label}>Email</Typography>      
          <TextField  
            className={classes.text}
            variant="outlined"
            size="small"
            id="email" 
            name="entry.1675632928"
            placeholder="nguyenvana@gmail.com"
            value={formik.values.email}
            onChange={changeName}
            />
          {formik.errors.email && formik.touched.email && 
          (<div className={classes.errors}>{formik.errors.email}</div>)} 
        </div>
        <div className={classes.row}> 
          <Typography  variant="h6" gutterBottom className={classes.label}>Số điện thoại</Typography>      
          <TextField  
            className={classes.text} 
            variant="outlined"
            size="small"
            id="phone" 
            name="entry.1994480500"
            placeholder="+818098926532"
            value={formik.values.phone}
            onChange={changeName}
            />
          {formik.errors.phone && formik.touched.phone && 
            (<div className={classes.errors}>{formik.errors.phone}</div>)}
        </div> 
        <div className={classes.row}> 
          <Typography  variant="h6" gutterBottom className={classes.label}>Cần được giải đáp về</Typography>   
          <TextField  
            className={classes.text} 
            variant="outlined"
            size="small"
            id="askSubject" 
            name="entry.119830660"
            placeholder="Từ vựng công nghệ thông tin"
            value={formik.values.askSubject}
            onChange={changeName}
            />                    
        </div>   
        <div className={classes.row}>
          <Typography  variant="h6" gutterBottom className={classes.label}>Nội dung cần được giải đáp</Typography>
          <TextField  
            className={classes.text}
            variant="outlined"
            id="askContent" 
            name="entry.1278965062"
            size="small"
            value={formik.values.askContent}
            onChange={changeName}
            multiline rows={3}/>                       
        </div>
      <Button variant="contained" color="primary" type="submit" className={classes.button}>
        Gửi ý kiến
      </Button>
      </form>
    </Container>
  );
}
export default HelpForm;