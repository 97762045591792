import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Title from "./Title"

const useStyles = makeStyles((theme) => ({
  root:{
    marginBottom : "3rem"
  },
  title:{
    color: "#005CB2",
    marginBottom:"20px",
    padding: "1.5rem 0rem 0rem 1rem",
    fontSize:"24px"
  },
  content:{
    padding:"0 1rem 1rem 2rem",
    marginBottom:"10px",
    fontWeight:"300"
  },
  botton:{
    margin: "0 auto",
    display: "flex",
    width: "180px",
    padding: "0.5rem 1rem"
  }

}));

const HelpFeedBack  = ({data}) => { 
  const classes = useStyles();
	return (
    <div className= {classes.root}> 
        <Title data={data.feed_back_title} />
        <Typography className={classes.content}>
          {data.feed_back_conten}
        </Typography>
        <Button 
            className={classes.botton} 
            variant="contained" 
            color="primary" 
            href="https://docs.google.com/forms/d/1at35B0k1anFEKXAddYgqT5NB8KKTDTGyhqE7LXRg1WY/viewform?edit_requested=true"
        >
          Bảng Kháo Sát
        </Button>
    </div>
)
}
export default HelpFeedBack;