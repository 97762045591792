import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Title from "./Title"
import Alink from "../../common/ALink"

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: "40px",
  },
  link: {
    textDecoration: "none",
  },
  bottom: {
    display: "block",
    margin: "0 auto",
    padding: "0.5rem 1rem",
  },
  content: {
    padding: "0 1rem 1rem 2rem",
    marginBottom: "10px",
    fontWeight: "300",
  },
}))

const HelpLearnNow = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Title data={data.learn_now_title} />
      <Typography className={classes.content}>
        {data.learn_now_content}
      </Typography>
      <Alink to="/topics" className={classes.link}>
        <Button className={classes.bottom} variant="contained" color="primary">
          {data.learn_now_title}
        </Button>
      </Alink>
    </div>
  )
}
export default HelpLearnNow
