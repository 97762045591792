import React from "react"
import { graphql } from "gatsby"
import withRoot from "../components/shell/withRoot"
import SEO from "../components/seo"
import Support from "../components/help/pageContents/Support"

function SupportPage({ data }) {
  return (
    <React.StrictMode>
      <SEO title="Support" />
      <Support data={data.helpJson} />
    </React.StrictMode>
  )
}

export default withRoot(SupportPage)
export const query = graphql`
  {
    helpJson {
      help {
        help_title
        help_content {
          id
          question
          answer {
            content
            img {
              alt
              src {
                childImageSharp {
                  fluid {
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
      feed_back {
        feed_back_conten
        feed_back_title
      }
      learn_now {
        learn_now_content
        learn_now_title
        link {
          src
        }
      }
    }
  }
`
